<template lang="html">
  <div class="editor-container">

    <trumbowyg v-model="step.description" :config="config" class="trumbowyg" placeholder="Add text here to show when step is reached..."></trumbowyg>

    <!-- <tiptap-vuetify
         v-model="step.description"
         :class="{'hideToolbar' : hideToolbar}"
         :extensions="extensions"
         placeholder="Add text here to show when step is reached..."
         :toolbar-attributes="{ dense: true, color: 'grey lighten-2'}"
         :card-props="{flat: true}"
         :editor-properties="{onBlur: updateStep, onFocus: onFocus}"
       /> -->
</div>
</template>

<script>
// Plugins are optional
import 'trumbowyg/dist/plugins/emoji/trumbowyg.emoji.min.js'
import 'trumbowyg/dist/plugins/emoji/ui/trumbowyg.emoji.min.css'

import '@/plugins/trumbowyg.giphy.js'
import 'trumbowyg/dist/plugins/giphy/ui/trumbowyg.giphy.min.css'



export default {
  name: "wysiwyg",
  props: ['step'],
  components: {

  },
  watch:{

  },
  data(){
    return{
      hideToolbar: true,
      // Get options from
      // https://alex-d.github.io/Trumbowyg/documentation
      config: {
        autogrow: true,
        defaultLinkTarget: '_blank',
        minimalLinks: true,
        urlProtocol: true,
        tagsToRemove: ['script', 'link'],
        btns: [
          ['strong', 'em', 'del'],
          ['unorderedList', 'orderedList'],
          ['link'],
          ['emoji','insertImage', 'giphy'],
        ],
        plugins:{
          giphy:{
            apiKey: 'qkHu4r25Z944pmrN7InAZVMI9sRjbYYD'
          }
        },
        trumboModalClass: 'MODALLLLLL'

      },

    }
  },
  methods:{

    updateStep(){
      // let data = JSON.parse(JSON.stringify(this.step));
      // console.log('tip tap UpdateStep', data);
      // this.$emit('updateStep', data)
      // this.hideToolbar = true;
    },
    checkModel(data){
      process.env.NODE_ENV === "development" ? console.log( 'blur wysiwyg', data ) : null;
    },
    // onFocus(){
    //   console.log('event on Focus');
    //   this.hideToolbar = false;
    // }
  },
  mounted(){
  }
}
</script>

<style lang="scss">
.editor-container{
  position: relative;
  // padding-top: 48px;
  .trumbowyg-box{
    display: block;
    position: relative;
    // border: 1px solid #ccc;
    border:none;
    width: 100%;
    min-height: 100px;
    height: 100%;
    margin: 10px auto;
    .trumbowyg-editor {
        padding: 5px;
        display: block;
        position: relative;
        border: none;
        width: 100%;
        min-height: 50px;
        margin: 0 auto;
    }
    .trumbowyg-editor[contenteditable=true]:empty::before{
      content: attr(placeholder);
      color: var(--v-secondary-darken-1);
    }
    .trumbowyg-editor[contenteditable=true]:empty:not(:focus)::before{
      content: attr(placeholder);
      color: var(--v-secondary-darken-1);
    }
    .trumbowyg-button-pane{
      background: #eee!important;
    }
  }
}
// .madeWithGiphy{
//   max-width: 100%;
//   position: relative;
//   img{
//     max-width: 100%;
//   }
//   svg{
//     position: absolute;
//     bottom: 15px;
//     left:5px;
//     width: 60px;
//     height: auto;
//     -webkit-filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
//     filter: drop-shadow( 3px 3px 2px rgba(0, 0, 0, .7));
//     path{fill:white;}
//   }
// }
body{
  .trumbowyg-modal{
    font-family: Roboto,sans-serif;
    line-height: 1.5;
    position: fixed;
    top: 0!important;
    left: 0!important;
    max-width: 100vw!important;
    height: 100vh!important;
    width: 100vw!important;
    background: rgba(0, 0, 0, .35);
    transform: none!important;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    display: grid;
    grid-template-columns: 1fr 350px 1fr;
    grid-template-rows:1fr 250px 1fr;
    &.giphy-modal-container{
      display: grid;
      grid-template-columns: 1fr 450px 1fr;
      grid-template-rows:1fr 450px 1fr;
    }

    .trumbowyg-modal-box{
      position: relative;
      transform: none!important;
      left: auto;
      width: auto;
      max-width:none;
      grid-row: 2 /2;
      grid-column: 2 / 2;
      button{
        display: inline-block;
        align-content: center;
        align-items: center;
        text-align: center;
      }
      .trumbowyg-giphy-close {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 36px;
        height: 36px;
        background: 0 0;
        border: 1px solid transparent;
        svg{
          width: 24px;
        }
      }
      .trumbowyg-giphy-modal-scroll {
        overflow: auto;
        overflow-x: hidden;
        height: 87%;
      }
      .trumbowyg-giphy-search{
        background: #ddd;
        display: block;
        width: 85%;
        margin: 2%;
        min-height: 36px;
        padding-left: 10px;
        padding-right: 150px;
      }
      .trumbowyg-powered-by-giphy {
          position: absolute;
          top: 16px;
          right: 60px;
          pointer-events: none;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
      }
    }
  }
  @media (max-width: 960px) {
    .trumbowyg-modal{
      grid-template-columns: 1fr 90% 1fr;
      grid-template-rows:1fr 250px 1fr;
       .trumbowyg-modal-box{
        grid-column: 2 / 2;
        grid-row: 2 / 2;
        max-width: none;
        width: auto;
      }
    }
  }// end media
}
</style>
